<template>
  <v-container class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0">
      <v-toolbar flat>
        <v-toolbar-title>
          <h4 class="primary--text">{{ $t('participation.registrations') }}</h4>
        </v-toolbar-title>

        <v-spacer/>

        <v-btn text plain x-small @click="download">
          {{ $t('interest.exportAsExcelSheet') }}
          <v-icon>fal fa-file-arrow-down fa-fw</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider class="primary"/>

      <v-card-text>
        <p v-if="fair.id" class="row ma-0 p-2">
          <v-chip
              v-if="fair.included.participations_submitted_count"
              class="mr-2"
              color="warning"
              pill
              small
          >
            {{ fair.included.participations_submitted_count }}
            {{ $t(`tables.participation.count.submitted`) }}
          </v-chip>
          <v-chip
              v-if="fair.included.participations_approved_count"
              class="mr-2"
              color="success"
              pill
              small
          >
            {{ fair.included.participations_approved_count }}
            {{ $t(`tables.participation.count.approved`) }}
          </v-chip>
          <v-chip
              v-if="fair.included.participations_cancelled_count"
              class="mr-2"
              color="gray"
              pill
              small
          >
            {{ fair.included.participations_cancelled_count }}
            {{ $t(`tables.participation.count.cancelled`) }}
          </v-chip>
          <v-chip
              v-if="fair.included.participations_rejected_count"
              class="mr-2"
              color="blue"
              pill
              small
          >
            {{ fair.included.participations_rejected_count }}
            {{ $t(`tables.participation.count.rejected`) }}
          </v-chip>
          <v-chip
              v-if="fair.included.participations_reprocessed_count"
              class="mr-2"
              color="primary"
              pill
              small
          >
            {{ fair.included.participations_reprocessed_count }}
            {{ $t(`tables.participation.count.reprocessed`) }}
          </v-chip>
          <v-chip
              v-if="fair.included.participations_waiting_count"
              class="mr-2"
              color="light-green"
              pill
              small
          >
            {{ fair.included.participations_waiting_count }}
            {{ $t(`tables.participation.count.waiting`) }}
          </v-chip>
          <v-chip
              v-if="fair.included.space_total"
              class="mr-2"
              color="primary"
              pill
              small
          >
            {{ $t('participation.totalSpace') }}: {{ fair.included.space_total }} m<sup>2</sup>
          </v-chip>
        </p>
        <small
            v-if="fair.included?.coexhibitors_count"
            class="pt-2"
        >
          {{ $t('participation.coExhibitors') }}: {{ fair.included?.coexhibitors_count }}
        </small>
      </v-card-text>

      <v-data-table
          :headers="headers"
          :loading="form.loading"
          :items="rows"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
          show-expand
      >
        <template v-slot:item.company="{ item }">
          <span v-if="!isSubexhibitor(item)">{{ item.relationships.company?.attributes.company }}</span>
          <span v-else>{{ item.sub_exhibitor.attributes.company }}</span>
        </template>

        <template v-slot:item.coexhibitors="{ item }">
          <span v-if="!isSubexhibitor(item)">{{ item.relationships.coexhibitors.length }}</span>
          <span v-else>-</span>
        </template>

        <template v-slot:item.is_subexhibitor="{ item }">
          <span v-if="isSubexhibitor(item)">UA</span>
          <span v-else>HA</span>
        </template>

        <template v-slot:item.contact_full="{ item }">
          <span v-if="!isSubexhibitor(item)">{{ item.attributes.contact_full }}</span>
          <span v-else>{{ item.sub_exhibitor.contact_full }}</span>
        </template>

        <template v-slot:item.booked="{ item }">
          <div v-if="!isSubexhibitor(item)">
            <span v-if="item.attributes.booking_type === BOOKING_TYPES.SPACE && item.relationships.spaces.length">
              {{ item.relationships.spaces.reduce((val, el) => el.attributes.size + val, 0) }} m<sup>2</sup>
              {{
                item.relationships.spaces[0].attributes.construction_included
                    ? $t('participation.exhibitionSpace.withStandConstruction')
                    : $t('participation.exhibitionSpace.withoutStandConstruction')
              }}
            </span>
            <span v-if="item.attributes.booking_type === BOOKING_TYPES.PACKAGE && item.relationships.package"
                  v-html="item.relationships.package.attributes.title[$i18n.locale]">
            </span>
          </div>
          <div v-else>
            <span>-</span>
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <v-tooltip bottom
                     v-if="item.attributes.denied_note && !item.included.is_approved && !item.included.is_submitted">
            <template v-slot:activator="{ on, attrs }">
              <v-chip :color="colorStatus(item)" pill small v-bind="attrs" v-on="on">
                {{ $t(`tables.participation.pageStatus.${item.attributes.status}`) }}
              </v-chip>
            </template>
            <span>{{ item.attributes.denied_note }}</span>
          </v-tooltip>
          <v-chip v-else :color="colorStatus(item)" pill small>
            {{ $t(`tables.participation.pageStatus.${item.attributes.status}`) }}
          </v-chip>
        </template>

        <template v-slot:item.changed="{ item }">
          <span>{{ changedAtStatus(item) | dateByLocale }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu
              v-if="!isSubexhibitor(item)"
              open-on-click
              bottom
              left
              offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn color="primary" icon v-on="on">
                <v-icon>fal fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :disabled="item.attributes.status === PARTICIPATION_STATUS.REPROCESSED"
                           @click="downloadRegistrationConfirmation({participation: item, all: true})">
                <v-list-item-title>
                  {{ $t('tables.participation.form.downloadRegistrationConfirmation') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewRegistration(item.id)">
                <v-list-item-title>
                  {{ $t('participation.actions.view') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="isAvailableToReprocess(item)"
                  @click="handleReprocessParticipation(item)"
              >
                <v-list-item-title>
                  {{ $t('participation.actions.returnForProcessing') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="isAvailableToApprove(item)"
                  @click="handleApproveParticipation(item)"
              >
                <v-list-item-title>
                  {{ $t('participation.actions.confirm') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="isAvailableToCancel(item)"
                  @click="handleCancelParticipation(item)"
              >
                <v-list-item-title>
                  {{ $t('participation.actions.cancel') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="isAvailableToReject(item)"
                  @click="handleRejectParticipation(item)"
              >
                <v-list-item-title>
                  {{ $t('participation.actions.reject') }}
                </v-list-item-title>
              </v-list-item>

              <!--              <v-list-item v-if="item.company.accreditationApprovedAt">-->
              <!--                <v-list-item-title @click="openModalAccreditation(item.company)">-->
              <!--                  {{ $t('participation.accreditation.viewData') }}-->
              <!--                </v-list-item-title>-->
              <!--              </v-list-item>-->

              <v-list-item
                  v-if="isAvailableToWaiting(item)"
                  @click="handleWaitingParticipation(item)"
              >
                <v-list-item-title>
                  {{ $t('participation.actions.waiting') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                    @click="$router.push({ name: 'organizer.notes', params: { id: item.relationships?.company.attributes.company_id } })">
                  {{ $t('help.notes.notes') }}
                </v-list-item-title>
                <v-badge
                    color="secondary"
                    v-if="item.included.notes_count"
                    :content="item.included.notes_count"
                    inline
                ></v-badge>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
              :colspan="headers.length"
              class="elevation-1 px-1 grey lighten-5"
          >
            <v-container fluid>
              <v-row>
                <v-col cols="3" v-if="isSubexhibitor(item)">
                  <small>{{ $t('participation.parentExhibitor') }}:</small>
                  <p>{{ item.parent_exhibitor }}</p>
                </v-col>

                <v-col cols="3" v-if="!isSubexhibitor(item)">
                  <small>{{ $t('participation.coExhibitor') }}</small><br/>
                  <ul v-if="item.relationships.coexhibitors">
                    <li v-for="coexhibitor in item.relationships.coexhibitors" :key="coexhibitor.id">
                      {{ coexhibitor.attributes.company }}
                    </li>
                  </ul>
                  <span v-else>
                    -
                  </span>

                  <p class="mt-3">
                    <small>{{ $t('participation.notificationOfTheParticipant') }}</small><br/>
                    {{ item.attributes.message ? item.attributes.message : '-' }}
                  </p>

                  <v-btn :disabled="item.attributes.status === PARTICIPATION_STATUS.REPROCESSED"
                         @click="downloadRegistrationConfirmation({participation: item, all: true})" color="secondary"
                         style="text-transform: none;" outlined text>
                    <v-icon class="mr-2" size="18">fal fa-file-pdf</v-icon>
                    {{ $t('tables.participation.form.downloadRegistrationConfirmation') }}
                  </v-btn>

                  <p class="mt-5"
                     v-if="item.attributes.denied_note && (item.attributes.status === PARTICIPATION_STATUS.REJECTED || item.attributes.status === PARTICIPATION_STATUS.CANCELLED)">
                    {{ $t('participation.configureRegistrationForm.rejectWithMessageModal.title') }}: <br/>
                    {{ item.attributes.denied_note }}
                  </p>

                  <v-btn v-if="item.relationships.company.attributes.accreditation_approved_at" class="mt-5" color="secondary"
                         style="text-transform: none;" outlined text
                         @click="openModalAccreditation(item.relationships.company)">
                    <v-icon class="mr-2" size="18">fal fa-info-circle</v-icon>
                    {{ $t('participation.accreditation.viewData') }}
                  </v-btn>

                  <v-btn class="mt-5" color="secondary" style="text-transform: none;" outlined text
                         @click="$router.push({ name: 'organizer.notes', params: { id: item.relationships.company.attributes.company_id } })">
                    <v-icon class="mr-2" size="18">fal fa-notes</v-icon>
                    {{ $t('help.notes.company') }}
                  </v-btn>

                  <v-badge color="secondary"
                           v-if="item.included.notes_count"
                           :content="item.included.notes_count"
                  >
                  </v-badge>
                </v-col>

                <v-col cols="3">
                  <small>{{ $t('participation.addressAndContactDetails') }}</small>
                  <p v-if="!isSubexhibitor(item)">
                    {{ item.relationships.company.attributes.company }}<br/>
                    <span v-if="item.attributes.brand"> {{ item.attributes.brand }} <br/> </span>
                    {{ item.relationships.company.attributes.street }}<br/>
                    <span v-if="item.relationships.company.attributes.address_supplement">{{
                        item.relationships.company.attributes.address_supplement
                      }}<br/></span>
                    {{ item.relationships.company.attributes.postalcode }} {{ item.relationships.company.attributes.city }},
                    {{ item.relationships.company.relationships.country.translations[$i18n.locale].country }}<br/>
                    <span v-if="item.relationships.federal_state">
                      {{
                        item.relationships.federal_state ? item.relationships.federal_state.name[$i18n.locale] : ''
                      }}<br/>
                    </span>
                  </p>
                  <p v-else>
                    {{ item.sub_exhibitor.attributes.company }}<br/>
                    {{ item.sub_exhibitor.attributes.street }}<br/>
                    {{ item.sub_exhibitor.attributes.postalcode }} {{ item.sub_exhibitor.attributes.city }}
                    <span v-if="item.sub_exhibitor.attributes.federal_state">
                      {{
                        item.sub_exhibitor.attributes.federal_state ? item.sub_exhibitor.attributes.federal_state.name[$i18n.locale] : ''
                      }}<br/>
                    </span>
                  </p>

                  <p v-if="!isSubexhibitor(item)">
                    <span v-if="item.relationships.company && item.relationships.company.attributes.email">
                      <a :href="'mailto:'+item.relationships.company.attributes.email">
                        {{ item.relationships.company.email }}
                      </a>
                      <br/>
                    </span>
                    <span v-if="item.relationships.company && item.relationships.company.attributes.website">
                      <a :href="item.relationships.company.attributes.website" target="_blank">
                        {{ item.relationships.company.attributes.website }}
                      </a>
                    </span>
                  </p>
                  <p v-else>
                    <span v-if="item.sub_exhibitor.attributes.company && item.sub_exhibitor.attributes.email">
                      <a :href="'mailto:'+item.sub_exhibitor.attributes.email ">
                        {{ item.sub_exhibitor.attributes.email }}
                      </a>
                      <br/>
                    </span>
                    <span v-if="item.sub_exhibitor && item.sub_exhibitor.attributes.website">
                      <a :href="item.sub_exhibitor.attributes.website" target="_blank">
                        {{ item.sub_exhibitor.attributes.website }}
                      </a>
                    </span>
                  </p>

                  <div v-if="!isSubexhibitor(item)">
                    <p v-if="item.relationships.company.attributes.vat_id || item.relationships.billingaddress_email">
                    <span v-if="item.relationships.company.attributes.vat_id">
                      {{ $t('forms.fields.vatID') }}: {{ item.relationships.company.attributes.vat_id }}
                      <br/>
                    </span>
                      <span v-if="item.relationships.billingaddress_email">
                      {{ $t('participation.billingaddressEmail') }}: {{ item.relationships.billingaddress_email }}
                    </span>
                    </p>
                  </div>


                  <p v-if="!isSubexhibitor(item)">
                    {{ $t('participation.phone') }}: {{ item.relationships.company.attributes.phone }}
                  </p>
                  <p v-else>
                    {{ $t('participation.phone') }}: {{ item.sub_exhibitor.attributes.phone }}
                  </p>

                  <p v-if="!isSubexhibitor(item)">
                    <small>{{ $t('participation.contactPerson') }}</small>
                    <span>
                      {{ item.attributes.contact_full }} <br/>
                      {{ $t('participation.phone') }}: {{ item.attributes.contact_phone }}<br/>
                      {{ $t('forms.fields.mobile') }}: {{ item.attributes.contact_mobile }}<br/>
                      <a :href="'mailto:'+item.attributes.contact_email">
                        {{ item.attributes.contact_email }}
                      </a>
                    </span>
                  </p>
                  <p v-else>
                    <small>{{ $t('participation.contactPerson') }}</small>
                    <span>
                      {{ item.sub_exhibitor.attributes.contact_full }} <br/>
                      {{ $t('participation.phone') }}: {{ item.sub_exhibitor.attributes.contact_phone }}<br/>
                      <a :href="'mailto:'+item.sub_exhibitor.attributes.contact_email">
                        {{ item.sub_exhibitor.attributes.contact_email }}
                      </a>
                    </span>
                  </p>

                  <p
                      v-if="
                      item.relationships.company.attributes.is_foreign_branch ||
                      item.relationships.company.attributes.is_foreign_representation
                    "
                  >
                    <v-icon class="primary--text mb-1 mr-3" size="14">
                      fal
                      {{
                        item.relationships.company.attributes.is_foreign_branch ? 'fa-check' : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{ $t('tables.accreditation.foreignBranch') }}</span
                    ><br/>
                    <v-icon class="primary--text mb-1 mr-3" size="14">
                      fal
                      {{
                        item.relationships.company.attributes.is_foreign_representation
                            ? 'fa-check'
                            : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{ $t('tables.accreditation.foreignRepresentation') }}</span
                    ><br/><br/>
                    <small>{{ $t('tables.accreditation.connectedCompany') + ': ' }}</small
                    ><br/>
                    <span>{{ item.relationships.company.attributes.related_company }} </span>
                  </p>
                </v-col>

                <v-col cols="6" class="py-1">
                  <table class="facts">
                    <tbody>
                    <tr v-if="!isSubexhibitor(item)">
                      <td class="pl-0">
                        <small>{{
                            $t('participation.bookedExhibitionSpace')
                          }}</small>
                      </td>
                      <td class="pl-0" v-if="!isSubexhibitor(item)">
                          <span
                              v-if="item.attributes.booking_type === BOOKING_TYPES.SPACE && item.relationships.spaces.length">
                            {{
                              item.relationships.spaces.reduce((val, el) => el.attributes.size + val, 0)
                            }} m<sup>2</sup>
                            {{
                              item.relationships.spaces[0].attributes.construction_included
                                  ? $t('participation.exhibitionSpace.withStandConstruction')
                                  : $t('participation.exhibitionSpace.withoutStandConstruction')
                            }}
                          </span>
                        <span
                            v-if="item.attributes.booking_type === BOOKING_TYPES.PACKAGE && item.relationships.package"
                            v-html="item.relationships.package.attributes.title[$i18n.locale]">
                          </span>
                      </td>
                    </tr>
                    <tr v-if="!isSubexhibitor(item) && item.relationships.areas">
                      <td class="pl-0">
                        <small>{{
                            $t('participation.exhibitionArea.title')
                          }}</small>
                      </td>
                      <td class="pl-0">
                          <span
                              v-for="(area, index) in item.relationships.areas"
                              :key="index"
                          >
                            {{ area.attributes.name[$i18n.locale] }}
                            <br/>
                          </span>
                      </td>
                    </tr>
                    <tr v-if="!isSubexhibitor(item) && item.relationships.services.filter((el) => el.attributes.fee).length">
                      <td class="pl-0">
                        <small>{{
                            $t(
                                'participation.standEquipmentAndAdditionalServices'
                            )
                          }}</small>
                      </td>
                      <td class="pl-0">
                          <span
                              v-for="(service, index) in item.relationships.services.filter((el) => el.attributes.fee)"
                              :key="index"
                          >
                            {{ $t('forms.fields.title') }}: {{ service.attributes.title[$i18n.locale] }}<br/>
                            {{ $t('forms.fields.amount') }}: {{ service.attributes.amount }}<br/>
                            {{
                              service.attributes.specifiable ? `${$t('forms.fields.specificationLabel')}: ${service.attributes.specification_label[$i18n.locale]} - ${service.attributes.specification}` : ''
                            }}
                            <br v-if="service.attributes.specifiable"/>
                            <br/>
                          </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0">
                        <small v-if="item.relationships.goods.length">{{
                            $t('participation.exhibitionGoods.title')
                          }}</small>
                      </td>
                      <td class="align-start">
                          <span
                              v-for="(good, index) in item.relationships.goods"
                              :key="index"
                          >
                            {{ good.attributes.title }}
                            <br/>
                          </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <accreditation-detail-modal ref="accreditation"></accreditation-detail-modal>
    <return-participant-message-modal ref="return"></return-participant-message-modal>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {PARTICIPATION_STATUS} from '@/enums/participationStatus'
import {BOOKING_TYPES} from "@/enums/bookingType";
import ReturnParticipantMessageModal
  from '@/components/organizer/Participants/Modals/ReturnParticipantMessageModal'
import AccreditationDetailModal from '@/components/organizer/Participants/Modals/AccreditationDetailModal'
import participationMode from '../../mixins/participationMode'

export default {
  name: 'OrganizerParticipants',

  mixins: [
    participationMode,
  ],

  components: {
    ReturnParticipantMessageModal,
    AccreditationDetailModal,
  },

  data() {
    return {
      fair: {},
      participations: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },

      BOOKING_TYPES,
      PARTICIPATION_STATUS,
    }
  },

  computed: {
    ...mapGetters('organizer', ['currentTradeFair']),

    ...mapGetters('countries', ['countries']),

    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand',
        },
        {
          text: this.$t('participation.company'),
          value: 'company',
          sortable: true,
        },
        {
          text: this.$t('participation.coExhibitor'),
          value: 'coexhibitors',
          sortable: true,
        },
        {
          text: this.$t('participation.haUa'),
          value: 'is_subexhibitor',
          sortable: false,
        },
        {
          text: this.$t('participation.contactPerson'),
          value: 'contact_full',
          sortable: true,
        },
        {
          text: this.$t('participation.booking'),
          value: 'booked',
          sortable: true,
        },
        {
          text: this.$t('participation.status'),
          value: 'status',
          sortable: true,
        },
        {
          text: this.$t('participation.changed'),
          value: 'changed',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ]
    },

    rows() {
      var arr = []

      if (!this.participations) {
        return arr
      }

      var participations = JSON.parse(JSON.stringify(this.participations))

      for (var i in participations) {
        participations[i].is_subexhibitor = false
        participations[i].sub_exhibitor = participations[i].relationships.coexhibitors
            .map((el) => el.attributes.company)
            .join('\r\n')

        arr.push(participations[i])

        if (!participations[i].relationships.coexhibitors.length) {
          continue
        }

        for (var key in participations[i].relationships.coexhibitors) {
          arr.push({
            ...participations[i],
            id: participations[i].relationships.coexhibitors[key].id,
            is_subexhibitor: true,
            sub_exhibitor: participations[i].relationships.coexhibitors[key],
            parent_exhibitor: participations[i].relationships.company.attributes.company,
          })
        }
      }

      for (var index in arr) {
        arr[index].pricingModel = this.calculatePriceType(arr[index])
      }

      return arr
    },
  },

  async created() {
    await this.fetchCurrentTradeFair()
    await this.fetchCountries()

    this.load()
  },

  methods: {
    ...mapActions('participation', [
      'reprocessParticipation',
      'approveParticipation',
      'cancelParticipation',
      'rejectParticipation',
      'waitingParticipation',
      'downloadRegistrationConfirmation',
    ]),

    ...mapActions('organizer', [
      'fetchCurrentTradeFair',
      'downloadTradeFairParticipants'
    ]),

    ...mapActions('tradefair', ['fetchParticipations']),

    ...mapActions('countries', ['fetchCountries']),

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response1 = (await this.$axios.get(`/api/trade-fairs/${this.currentTradeFair.id}/participants`, {
        params: {
          'languages': '*',
          'include': [
            'tradeFair',
            'coexhibitors',
            'company',
            'tradeFairSpaces',
            'tradeFairPackages',
            'tradeFairServices',
            'areas',
            'goods',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      let response2 = (await this.$axios.get(`/api/trade-fairs/${this.currentTradeFair.id}`, {
        params: {
          'languages': '*',
          'include': [
            'participations',
          ],
        }
      })).data

      this.fair = response2.data
      this.participations = response1.data

      this.form.total = response1.meta.paginator.total
      this.form.loading = false
    },

    isSubexhibitor(item) {
      return item.is_subexhibitor
    },

    openModalAccreditation(company) {
      this.$refs.accreditation.show(company)
    },

    viewRegistration(id) {
      this.$router.push({
        name: 'organizer.participants.detail',
        params: {id: id},
      })
    },

    isAvailableToApprove(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.REJECTED,
        PARTICIPATION_STATUS.WAITING,
      ].includes(participation.attributes.status)
    },

    isAvailableToReprocess(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.REJECTED,
        PARTICIPATION_STATUS.WAITING,
      ].includes(participation.attributes.status)
    },

    isAvailableToCancel(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.WAITING,
      ].includes(participation.attributes.status)
    },

    isAvailableToReject(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.WAITING,
      ].includes(participation.attributes.status)
    },

    isAvailableToWaiting(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.REJECTED,
      ].includes(participation.attributes.status)
    },

    colorStatus(participation) {
      switch (participation.attributes.status) {
        case PARTICIPATION_STATUS.SUBMITTED:
          return 'warning'
        case PARTICIPATION_STATUS.APPROVED:
          return 'success'
        case PARTICIPATION_STATUS.CANCELLED:
          return 'gray'
        case PARTICIPATION_STATUS.REJECTED:
          return 'gray'
        case PARTICIPATION_STATUS.REPROCESSED:
          return 'primary'
        case PARTICIPATION_STATUS.WAITING:
          return 'light-green'
        default:
          return 'gray'
      }
    },

    changedAtStatus(participation) {
      switch (participation.attributes.status) {
        case PARTICIPATION_STATUS.SUBMITTED:
          return participation.attributes.submitted_at
        case PARTICIPATION_STATUS.APPROVED:
          return participation.attributes.approved_at
        case PARTICIPATION_STATUS.CANCELLED:
          return participation.attributes.cancelled_at
        case PARTICIPATION_STATUS.REJECTED:
          return participation.attributes.rejected_at
        case PARTICIPATION_STATUS.REPROCESSED:
          return participation.attributes.rejected_at
        case PARTICIPATION_STATUS.WAITING:
          return participation.attributes.waiting_at
        default:
          return ''
      }
    },

    // eslint-disable-next-line no-unused-vars
    async handleReprocessParticipation(participation) {
      this.$refs.return.show(async (deniedNote) => {
        await this.reprocessParticipation({id: participation.id, deniedNote})

        this.load()
      })
    },

    async handleApproveParticipation(participation) {
      await this.approveParticipation(participation.id)

      this.load()
    },

    // eslint-disable-next-line no-unused-vars
    async handleCancelParticipation(participation) {
      this.$refs.return.show(async (deniedNote) => {
        await this.cancelParticipation({id: participation.id, deniedNote})

        this.load()
      })
    },

    async handleRejectParticipation(participation) {
      this.$refs.return.show(async (deniedNote) => {
        await this.rejectParticipation({id: participation.id, deniedNote})

        this.load()
      })
    },

    async handleWaitingParticipation(participation) {
      await this.waitingParticipation({id: participation.id})

      this.load()
    },

    async download() {
      await this.downloadTradeFairParticipants({
        id: this.currentTradeFair.id,
        name: this.currentTradeFair.nameDisplay + ' participants'
      })
    }
  },

  watch: {
    'options'() {
      if (this.currentTradeFair.id) {
        this.load()
      }
    },

    'filters': {
      handler() {
        this.options.page = 1

        this.load()
      },
      deep: true
    },
  },
}
</script>

<style scoped>
.v-list-item {
  cursor: pointer;
}
</style>
