const PARTICIPATION_STATUS = {
  SUBMITTED: 'submitted',
  CANCELLED: 'cancelled',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  REPROCESSED: 'reprocessed',
  CHECK_REGISTRATION: 'checkRegistration',
  COMPLETED: 'completed',
  WAITING: 'waiting',
}

export {
  PARTICIPATION_STATUS,
}
